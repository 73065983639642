import axios from 'axios';
import {httpMethod} from "@/helpers/httpMethod";
import Swal from "sweetalert2";
import store from "@/state/store";

export async function getInstance(endPoint, data = {}, method = httpMethod.post, checkLoading = true) {
    try {
        if (checkLoading) {
            await store.dispatch('loader/setLoading', true);
        }
        let jwtToken = localStorage.getItem('jwt');
        let instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            timeout: 50000,
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
        });
        let response = undefined;
        if (method === httpMethod.post) {
            response = await instance.post(endPoint, data);
        }
        if (method === httpMethod.get) {
            response = await instance.get(endPoint);
        }
        if (method === httpMethod.put) {
            response = await instance.put(endPoint, data);
        }
        if (method === httpMethod.delete) {
            response = await instance.delete(endPoint, data);
        }
        if (!response || (response && response.status !== 200)) {
            const message = response?.data?.message??'';
            await showErrorMessage(message);
        } else {
            if (!response.data.status) {
                await showErrorMessage(response.data.message);
            }
        }
        return response;
    } catch (error) {
        await showErrorMessage();
        const statusCode = error.response.status;
        if (statusCode === 401) {
            await store.dispatch('auth/logOut');
            window.location.href = '/login'
        }
        return error.response;
    } finally {
        if (checkLoading) {
            await store.dispatch('loader/setLoading', false);
        }
    }
}

export async function getInstanceMultipart(endPoint, formData) {
    try {
        await store.dispatch('loader/setLoading', true);
        let jwtToken = localStorage.getItem('jwt');
        let instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            timeout: 50000,
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            },
        });
        return await instance.post(endPoint, formData);
    } catch (error) {
        await showErrorMessage();
        const statusCode = error.response.status;
        if (statusCode === 401) {
            await store.dispatch('auth/logOut');
            window.location.href = '/login'
        }
        return error.response;
    } finally {
        await store.dispatch('loader/setLoading', false);
    }
}

function showErrorMessage(message = 'Something went wrong!') {
    Swal.fire({
        title: 'Oops...',
        text: message,
        icon: 'error',
        timer: 10000,
    }).then((result) => {
        console.log('show message: ' + result)
    });
}
