export const endPoint = {
  login: 'auth/login',
  logout: 'auth/logout',
  info: 'auth/info',
  fileFolderGets: 'admin/file/folder-gets',
  fileGetsByFolder: 'admin/file/file-gets-by-folder',
  fileUpload: 'admin/file/upload',

  toolCategoryGet: 'admin/tool-category/get',
  toolCategoryGets: 'admin/tool-category/gets',
  toolCategoryAdd: 'admin/tool-category/add',
  toolCategoryUpdate: 'admin/tool-category/update',
  toolCategoryStatusChange: 'admin/tool-category/status-change',

  toolGet: 'admin/tool/get',
  toolGets: 'admin/tool/gets',
  toolAdd: 'admin/tool/add',
  toolUpdate: 'admin/tool/update',
  toolStatusChange: 'admin/tool/status-change',

  cacheClear: 'admin/cache/clear',

  menuGet: 'admin/menu/get',
  menuGets: 'admin/menu/gets',
  menuAdd: 'admin/menu/add',
  menuUpdate: 'admin/menu/update',
  menuLoadItem: 'admin/menu/load-items',
  menuAddItem: 'admin/menu/add-item',
  menuStatusChange: 'admin/menu/status-change',

  emojiGet: 'admin/emoji/get',
  emojiGets: 'admin/emoji/gets',
  emojiAdd: 'admin/emoji/add',
  emojiUpdate: 'admin/emoji/update',
  emojiStatusChange: 'admin/emoji/status-change',

  emojiCategoryGet: 'admin/emoji-category/get',
  emojiCategoryGets: 'admin/emoji-category/gets',
  emojiCategoryAdd: 'admin/emoji-category/add',
  emojiCategoryUpdate: 'admin/emoji-category/update',
  emojiCategoryStatusChange: 'admin/emoji-category/status-change',

};
